import React from "react";
import PropTypes from "prop-types";

import Box from "@pagerland/common/src/components/Box";
import Typography from "@pagerland/common/src/components/Typography";
import Container from "@pagerland/common/src/components/Container";

import Theme, { theme } from "../../packages/themes/src/Startup";
import { Copyright, Legal } from "../../packages/themes/src/Startup/containers";

const NotFound = ({ TitleProps }) => (
  <Theme>
    <Box>
      <Container>
        <Box>
          <Typography {...TitleProps}>Page not found</Typography>
        </Box>
      </Container>
    </Box>
    <Copyright />
  </Theme>
);

NotFound.propTypes = {
  /**
   * Title text props
   * @See @pagerland/common/src/components/Typography
   */
  TitleProps: PropTypes.object,
};

NotFound.defaultProps = {
  TitleProps: {
    as: "h1",
    variant: "h1",
    mb: 4,
    mx: "auto",
    color: "black",
  },
};

export default NotFound;
